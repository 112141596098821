import { Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import commonFunction from "../../Utils/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "../../app/transaction/Transaction.slice";
import Loader from "../Common/Loader";
import DefaultPagination from "../../config/Pagination";
import Pagination from "../Common/Pagination";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import "./Profile.css";

const { RangePicker } = DatePicker;

function UserTransaction({ showTransaction }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [transactions, setTransactions] = useState();

  // Use state to manage transaction
  const [transactionData, setTransactionData] = useState({
    search: "",
    pageSize: DefaultPagination.PAGE_SIZE,
    pageNumber: DefaultPagination.PAGE_NUMBER,
    startDate: selectedDates.start, // Include startDate and endDate in transactionData
    endDate: selectedDates.end,
  });

  const validationSchema = yup.object({
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .required(" Beløb er Nødvendig Får at Fortsætte")
      .max(1000, "Amount cannot be greater than 1000"),
  });

  useEffect(() => {
    dispatch(getTransaction(transactionData));
  }, [transactionData, dispatch]);

  useEffect(() => {
    const getTransactions = commonFunction.getTransactions(transactionData);
    getTransactions.then((res) => {
      setTransactions(res.data);
    });

    // window.scrollTo(0, 0);
  }, [transactionData]);
  const convertToBackendFormat = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = async (dates, dateStrings) => {
    const formattedStartDate = convertToBackendFormat(dateStrings[0]);
    const formattedEndDate = convertToBackendFormat(dateStrings[1]);
    const obj = {
      start: formattedStartDate,
      end: formattedEndDate,
    };
    setSelectedDates(obj);
    setTransactionData((prevData) => ({
      ...prevData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    const getTransaction = await commonFunction.getTransactions(
      transactionData
    );
    toast.success("Transactions fetched successfully");
    setTransactions(getTransaction?.data);
  };

  // Function to format date in YYYY-MM-DD format
  // const formatDate = (dateString) => {
  //     return new Date(dateString).toISOString().slice(0, 10);
  // };
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where date might be undefined or null

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const paginationWithPage = (pageNumber) => {
    setTransactionData((prevData) => ({
      ...prevData,
      pageNumber: pageNumber,
    }));
  };

  // Get the transaction list using state variable
  const transactionList = useSelector(
    (state) => state?.Transaction?.transaction
  );

  const formik = useFormik({
    initialValues: {
      amount: "",
    },

    onSubmit: async (values) => {
      setLoading(true);
      try {
        localStorage.setItem("cancelMsg", "transactionPage");
        let id = "";
        const response = await commonFunction.addTransaction(values);
        if (response.status) {
          id = response?.data?.id;
          const paymentObj = {
            currency: "DKK",
            id: id,
            amount: values?.amount,
          };

          await commonFunction
            .get3DSecureUrl(paymentObj)
            .then((res) => {
              if (res.status === 500) {
                toast.error("Failed to create 3D Secure URL");
                setLoading(false);
                dispatch(getTransaction(transactionData));
                return;
              } else if (res.status === true) {
                window.location.href = res?.data?.url;
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error, "error");
              setLoading(false);
              toast.error("Failed to create payment");
            });
          // await commonFunction.createPayment(paymentObj).then((res) => {
          //     if (res.data.id) {
          //         navigate('/make-payment', {
          //             state: {
          //                 amount: values.amount * 100,
          //                 paymentRes: res,
          //                 orderId: orderId,
          //             },
          //         });
          //         setLoading(false);
          //     } else {
          //         setLoading(false);
          //         toast.error('Payment failed! Somthing went wrong');
          //     }
          // });
          formik.resetForm();
        } else {
          console.error(response.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("Please wait!! Something went wrong !!");
      }
    },
    validationSchema,
  });

  useEffect(() => {
    if (localStorage.getItem('cancelMsg') == 'transactionPage') {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <>
      <div
        className={
          showTransaction ? "tab-pane fade show active" : "tab-pane fade"
        }
        id="transaction"
        role="tabpanel"
        aria-labelledby="transaction-tab"
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="tab_innerBody">
            <div className="tab_innerBodyTitle">
              <div
                className="my_transactions"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Mine Indbetalinger</h4>
                <div
                  className="transactions_Details"
                  style={{ display: "flex", width: "auto" }}
                >
                  <h4 className="p-2">Indbetalinger Oversigt</h4>
                  <RangePicker
                    className="transaction_titles"
                    placeholder={["Start Dato", "Slut Dato"]}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                  />
                </div>
              </div>
            </div>
            <div className="tab_subItems">
              <div className="tab_tableItem">
                <div className="row">
                  <div className="col-md-6 transactions_heading">
                    <h3 className="transactions_head">
                      {" "}
                      Liste Over Alle Mine Indbetalinger Oversigter
                    </h3>
                    <h6 className="transactions_text">
                      I Tilfælde af Problemer med indbetaling, Venligst have dit
                      Quickpay ID Klar på forhånd tak.
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <form onSubmit={formik.handleSubmit}>
                      <Space.Compact
                        className="transaction_btn"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Input
                          placeholder="Der kan Max Indbetales 1000 Kr"
                          type="number"
                          name="amount"
                          min={1} // Sets minimum value
                          max={1000}
                          value={formik.values.amount}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          style={{
                            width: "53%",
                            position: "relative",
                            left: "10px",
                          }}
                        />
                        {/* <button
                          type="submit"
                          style={{ width: "30%" }}
                          className="btn btn-primary fa fa-credit-card"
                        >
                          Indbetal
                        </button> */}
                        <button
                          type="submit"
                          style={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="btn btn-primary"
                        >
                          <i
                            className="fa fa-credit-card"
                            style={{ marginRight: "8px" }}
                          ></i>{" "}
                          {/* Adjusts the spacing */}
                          Indbetal
                        </button>
                      </Space.Compact>
                      {formik.touched.amount && formik.errors.amount ? (
                        <div className="error-message">
                          {formik.errors.amount}
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
                <div className="inner_data">
                  <div className="tab_subItems">
                    <div className="tab_tableItem">
                      <table className="table table-striped text-center">
                        <thead>
                          <tr className="table_fields">
                            {/* <th scope="col">ID</th> */}
                            <th scope="col">Quickpay ID</th>
                            <th scope="col">Beløb</th>
                            <th scope="col">Status</th>
                            <th scope="col">Dato</th>
                          </tr>
                          {transactions?.data?.result !== undefined &&
                            transactionList?.result?.length > 0 ? (
                            transactions?.data?.result.map((item, index) => (
                              <tr key={index}>
                                {/* <td>{item?.id}</td> */}
                                <td>{item?.transactionId}</td>
                                <td>{item?.amount}</td>
                                <td>
                                  {item.status === "processed" ||
                                    item.status === "completed" ||
                                    item.status === 1 ? (
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                    >
                                      Paid
                                    </button>
                                  ) : item.status === "cancel" ? (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                    >
                                      Cancel
                                    </button>
                                  ) : item.status === "refund" ? (
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                    >
                                      Refund
                                    </button>
                                  ) : item.status === "rejected" ? (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                    >
                                      Rejected
                                    </button>
                                  ) : item.status === "initial" ? (
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                    >
                                      Initial
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                    >
                                      Unknown
                                    </button>
                                  )}
                                </td>
                                <td>{formatDate(item?.createdAt)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5}>No order found !!</td>
                            </tr>
                          )}
                        </thead>
                      </table>
                    </div>
                    {/* Pagination component */}
                    <Pagination
                      totalPage={transactionList?.pagination?.totalPages}
                      currentPage={transactionList?.pagination?.currentPage}
                      fullPagination={paginationWithPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserTransaction;
