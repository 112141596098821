import React, { useEffect } from 'react'
import { Accordion } from '../Common/Accordion/Accordion';


export const Faq = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="page-info-sec">
        <div className="bg-fixed wow fadeIn" data-wow-delay="0.1s" style={{ background: 'url("assets/img/page-info1.png")' }} />
        <div className="bg-fixed img-overlay wow fadeIn" data-wow-delay="0.2s" />
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
              <div className="page-info-block wow fadeInUp" data-wow-delay="0.3s">
                <h1>FAQ</h1>
                <div className="btm-line-2 text-center justify-content-center">
                  <span />
                  <span />
                </div>
                <p>Her kan du se Ofte Stillede Spørgsmål
Guidende her er en god hjælp til de problemer der kan opstår.
                </p></div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-pd rel-z-1">
        <div className="top-bottom-shaps">
          <span className="shap shap-1" />
          <span className="shap shap-2" />
        </div>
        <div className="container">
          <div className="title-1 title-center wow fadeInUp" data-wow-delay="0.2s">
            <h1>Ofte Stillede Spørgsmål</h1>
            <div className="btm-line-1 text-center justify-content-center">
              <span />
              <span />
            </div>
          </div>
          <Accordion />
        </div>
      </section>
    </>
  );
}
