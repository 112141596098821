import React from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import commonFunction from "../../Utils/commonFunction";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

function UserProfile({ resetProfile }) {
  const user = useSelector((state) => state.user);

  // Manage initialValues state
  const [initialValues, setInitialValues] = useState({
    firstName: user?.user?.data?.firstName || "",
    lastName: user?.user?.data?.lastName || "",
    city: user?.user?.data?.city || "",
    mobileNumber: user?.user?.data?.mobileNumber || "",
  });

  const validationSchema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    city: Yup.string(),
    // firstName: Yup.string().required("First name is required"),
    // city: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .max(8, "Skal være dansk nummer på 8 tal ")
      .min(8, "Skal være dansk nummer på 8 tal ")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Detect changes
        const updatedValues = Object.keys(values).reduce((acc, key) => {
          if (values[key] !== initialValues[key]) {
            acc[key] = values[key];
          }
          return acc;
        }, {});

        if (Object.keys(updatedValues).length > 0) {
          const response = await commonFunction.updateUserProfile(
            updatedValues
          );
          toast.success(response.message);

          // Update initial values to reflect the saved changes
          setInitialValues((prev) => ({ ...prev, ...updatedValues }));
        } else {
          toast.info("No changes to update.");
        }
      } catch (error) {
        console.error("An error occurred while updating profile:", error);
        toast.error(error?.response?.data?.message);
      }
    },
  });

    // Reset form when the tab is activated
    useEffect(() => {
      if (resetProfile) {
        formik.resetForm({ values: initialValues });
      }
    }, [resetProfile]);

  return (
    <div
      className="tab-pane fade"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div className="tab_innerBody">
        <div className="tab_innerBodyTitle">
          <h4>Min Profil</h4>
        </div>
        <div className="tab_subItems">
          <div className="form_control">
            {/* <h4>Personal Information</h4> */}
            <form
              className="wow fadeIn"
              data-wow-delay="0.2s"
              onSubmit={formik.handleSubmit}
            >
              <div className="form-row">
                <div className="form-group form_groups">
                  <div className="col-md-2">
                    <label htmlFor="firstname">Fornavn</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName || ""}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="error-message">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-md-12 form_groups">
                  <div className="col-md-2">
                    <label htmlFor="lastname">Efternavn</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName || ""}
                    />
                  </div>
                </div>
                <div className="form-group col-md-12 form_groups">
                  <div className="col-md-2">
                    <label htmlFor="username">Brugernavn</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      disabled
                      value={user?.user?.data?.email || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group form_groups">
                <div className="col-md-2">
                  <label htmlFor="address">By</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city || ""}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error-message">{formik.errors.city}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group form_groups" style={formik.errors.mobileNumber ? { alignItems: 'initial' } : { alignItems: 'self-start' }}>
                <div className="col-md-2">
                  <label htmlFor="phone">Telefon Nummer</label>
                </div>
                <div className="col-md-3">
                  <div className="d-flex filed_inner_phone">
                    <div className="filed_inner filed_inner_select">
                      <input
                        type="text"
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        onChange={(event) => {
                          const { value } = event.target;
                          if (/^[0-9]*$/.test(value)) {
                            formik.handleChange(event);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber || ""}
                      />
                      {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber ? (
                        <div className="error-message">
                          {formik.errors.mobileNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10 offset-md-2">
                <button type="submit" className="btn btn-1">
                Opdater
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
