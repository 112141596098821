import React, { useState } from 'react'
import { Navbar } from '../Navbar'
import { Link } from 'react-router-dom';
import logo from '../../../logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import axios from 'axios';
import { userDetail } from '../../../app/login/login.slice';
import { getWalletAmount } from '../../../app/wallet/wallet.slice';
import { useEffect } from 'react';
import { base_url } from '../../../Utils/baseUrl';
import { config } from '../../../Utils/axiosconfig';
function Header() {
    const [navActive, setNavActive] = useState(false);
    const contactDetails = useSelector((state) => state.contact);
    const handleToggle = () => {
        navActive === false ? setNavActive(true) : setNavActive(false);

        // Add or remove class from body tag based on navActive state
        if (!navActive) {
            document.body.classList.add('menu-show');
        } else {
            document.body.classList.remove('menu-show');
        }
    }
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Detect mobile view
    const userWalletAmount = useSelector((state) => state.wallet?.amount);
    const dispatch = useDispatch();
    const orderDetails = useSelector((state) => state.order?.order);


    const user = useSelector((state) => state.user);

    const style = {
        // background: "#000000",
        color: '#ffffff',
        padding: '0px',
        borderRadius: '5px',
        fontSize: '9px',
        position: "relative",
        top: "-14px",
        right: "21px",
    };
    const walletStyle = {
        background: '#ee4d1a',
        padding: '7px',
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            dispatch(userDetail(config));
            dispatch(getWalletAmount());
        }
    }, [dispatch]);

    useEffect(() => {
        updateWalletAmount();
    }, []);

    const updateWalletAmount = async () => {
        try {
            await axios.get(`${base_url}transaction/completePaymentWalletUpdate`);
        } catch (error) {
            console.error("Error completing payment:", error);
        }
    };
    return (
        <>
            {/* Header */}
            <div className="header-top-strip wow fadeInDown" data-wow-delay="0.0s">
                <div className="container">
                    <ul className="icon-list">
                        <li className="wow fadeInDown" data-wow-delay="0.3s"><span><i className="fas fa-envelope" />{contactDetails.contact?.data?.email}</span></li>
                        <li className="wow fadeInDown" data-wow-delay="0.1s"><a href={`tel:${contactDetails.contact?.data?.phone}`}> <i className="fas fa-phone-alt" />{contactDetails.contact?.data?.phone}</a></li>
                    </ul>
                </div>
            </div>
            <header className="header wow fadeIn" data-wow-delay="0.0s">
                <div className="container">
                    <div className="top-logo-nav d-flex-wrap justify-content-between align-items-center" style={{ position: 'relative', right: '60px' }}>
                        <Link to={'/'} className='navbar-brand wow fadeInLeft' data-wow-delay="0.1s" >
                            <img src={logo} alt='not found' style={{ width: '18rem', marginTop: '13px' }} />
                        </Link>
                        <div className="tln-right">
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={handleToggle}>
                                <span />
                                <span />
                                <span />
                            </button>
                            <Navbar
                                setNavActive={setNavActive}
                                navActive={navActive}
                            />
                        {isMobileView && user?.user?.status ? (
                            <span style={style}>
                                {/* {orderDetails?.totalQuantity || 0} pieces DKK {orderDetails?.totalPrice || 0} */}
                                    <span style={walletStyle} className="m-3">
                                    Min Balance: {userWalletAmount.wallet} Kr
                                    </span>
                                
                            </span>
                        ) : ('')}
                        </div>
                    </div>
                </div>
            </header>
            {/* Header */}
        </>
    )
}

export default Header