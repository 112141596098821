import React from 'react'
import "./NoticeModal.css";

const NoticeModal = ({ show, onClose, heading }) => {
    if (!show) return null;

    return (
        <>
            <div className="modal-overlay">
                <div className="modal-container">
                    <div className="modal-header">
                        <h2>{heading}</h2>
                        <button className="modal-close" onClick={onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            I vores bestræbelser på at holde Priserne på de rimeligst tænkelige niveau, vil I opleve, at vi følger
                            markedsudviklingen i vores egne indkøbspriser og omkostninger tæt, og løbende justerer vores
                            priser/takster/leverancer ned igen, hvis det bliver muligt.<br /><br />

                            Leverandører har i de nye år steget i priserne så vi er nød til justere priserne på produkter, Vi vil fra Onsdag
                            22 Januar lave Prisstigninger.<br /><br />

                            Vi håber i forstår, vi gør vores bedste får at holde til bedst rimelige mulige pris.<br /><br />

                            Og hvis i har bogmærket vores side på jeres telefon anbefaler vi at i sletter den og bogmærker den igen får at få
                            senest opdatering.<br /><br />

                            Mvh<br />
                            Onlineskolemad
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-footer-close" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoticeModal