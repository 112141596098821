import React, { useEffect, useState } from 'react';
import commonFunction from '../../Utils/commonFunction';
import { useDispatch, useSelector } from 'react-redux';
import { userDetail } from '../../app/login/login.slice';
import { config } from '../../Utils/axiosconfig';
import Cookies from 'js-cookie';
import { getWalletAmount } from '../../app/wallet/wallet.slice';

import { base_url } from '../../Utils/baseUrl';
import axios from 'axios';

function ProfileHome({ isOrder, addChild, showTransaction }) {
    const [pageData, setPageData] = useState('');
    const [pageHeading, setPageHeading] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Detect mobile view
    const userWalletAmount = useSelector((state) => state.wallet?.amount);
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const orderDetails = useSelector((state) => state.order?.order);

    const style = {
        background: "#000000",
        color: '#ffffff',
        padding: '0px',
        borderRadius: '5px',
        fontSize: '16px'
    };
    const style2 = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
    const walletStyle = {
        background: '#ee4d1a',
        padding: '2px 5px',
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            dispatch(userDetail(config));
            dispatch(getWalletAmount());
        }
    }, [dispatch]);

    useEffect(() => {
        updateWalletAmount();
    }, []);

    const updateWalletAmount = async () => {
        try {
            await axios.get(`${base_url}transaction/completePaymentWalletUpdate`);
        } catch (error) {
            console.error("Error completing payment:", error);
        }
    };

    useEffect(() => {
        let id = 2;
        commonFunction.getPageContent(id).then((res) => {
            setPageData(res?.data?.page?.description);
            setPageHeading(res?.data?.page?.heading);
        }).catch((err) => {
            console.log(err, 'error');
        });
    }, []);



    return (
        <div
            className={isOrder || addChild || showTransaction ? "tab-pane fade" : "tab-pane fade show active"}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
        >
            <div className="tab_innerBody">
                <div className="tab_innerBodyTitle">
                    <div style={style2}>
                        <h4>{pageHeading}
                            {/* {isMobileView && user?.user?.status ? (
                                <span style={style}> */}
                                    {/* {orderDetails?.totalQuantity || 0} pieces DKK {orderDetails?.totalPrice || 0} */}
                                    {/* <span style={walletStyle} className="m-3">
                                        {userWalletAmount.wallet} Kr
                                    </span>

                                </span>
                            ) : ('')} */}
                        </h4>
                    </div>
                </div>
                <div className="tab_subItems">
                    <div className="tab_tableItem">
                        <div dangerouslySetInnerHTML={{ __html: pageData }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileHome;
