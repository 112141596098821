import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import commonFunction from "../../Utils/commonFunction";
import DefaultPagination from "../../config/Pagination";
import Pagination from ".././Common/Pagination";
import ReceiptModal from "../ProfileModals/receipt";
import { MENU_IMAGE_PATH, image_url } from "../../Utils/baseUrl";
import { DatePicker } from "antd";
import Cancelorder from "../ProfileModals/cancelorder";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;

function UserOrders({ isOrder }) {
  const [orders, setOrders] = useState();
  const [receiptData, setReceiptData] = useState();
  const [isRecModalOpen, setIsRecModalOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState({}); // For selecting the date range of  order transaction
  const closeRecModal = () => setIsRecModalOpen(false);
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const closeCancelOrderModal = () => setIsCancelOrderModalOpen(false);
  const [id, setId] = useState();
  const [orderItem, setOrderItem] = useState();

  let orderData = {
    search: "",
    pageNumber: DefaultPagination.PAGE_NUMBER,
    pageSize: DefaultPagination.PAGE_SIZE,
    startDate: selectedDates.start,
    endDate: selectedDates.end,
  };

  useEffect(() => {
    const getOrders = commonFunction.getOrders(orderData);
    getOrders.then((res) => {
      setOrders(res.data);
    });

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const paginationWithPage = (pageNumber) => {
    orderData.pageNumber = pageNumber;
    const getOrders = commonFunction.getOrders(orderData);
    getOrders.then((res) => {
      setOrders(res.data);
    });
  };

  const handleCancel = async (id, item) => {
    setOrderItem(item);
    setId(id);
    setIsCancelOrderModalOpen(true);
  };

  const handleReceipt = (data) => {
    setIsRecModalOpen(true);
    setReceiptData(data);
  };
  const convertToBackendFormat = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  // For the order transaction
  const handleDateChange = async (dates, dateStrings) => {
    const formattedStartDate = convertToBackendFormat(dateStrings[0]);
    const formattedEndDate = convertToBackendFormat(dateStrings[1]);
    const obj = {
      start: formattedStartDate,
      end: formattedEndDate,
    };
    orderData.startDate = formattedStartDate;
    orderData.endDate = formattedEndDate;
    const getOrders = await commonFunction.getOrders(orderData);
    toast.success("Order fetched successfully");
    setSelectedDates(obj);
    setOrders(getOrders?.data);
  };

  return (
    <div
      className={isOrder ? "tab-pane fade show active" : "tab-pane fade"}
      id="order"
      role="tabpanel"
      aria-labelledby="order-tab"
    >
      <div className="tab_innerBody">
        <div className="tab_innerBodyTitle">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Mine Ordre</h4>
            <div style={{ display: "flex", width: "auto" }}>
              <h4 className="p-2">Ordrer Detaljer</h4>
              {/* <RangePicker value={selectedDates} onChange={handleDateChange} /> */}
              <RangePicker
                placeholder={["Start Dato", "Slut Dato"]}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
              />
            </div>
          </div>
        </div>
        <div className="tab_subItems">
          <div className="tab_tableItem">
            <div className="text-right mb-3">
              <NavLink className="btn btn-secondary" to="/make-order">
                Opret Bestilling Her
              </NavLink>
            </div>
            <table className="table table-striped text-center myOrder" style={{ tableLayout: 'auto !important' }}>
              <thead>
                <tr>
                  <th scope="col">Bestilling ID</th>
                  <th scope="col">Barn</th>
                  <th scope="col">Bestilling</th>
                  <th scope="col">Billedet</th>
                  <th scope="col">Pris</th>
                  <th scope="col">Antal</th>
                  <th scope="col">Total</th>
                  <th scope="col">Leveringsdato</th>
                  <th scope="col">Kvittering</th>
                  <th scope="col">Slet Bestilling</th>
                </tr>
                {orders?.data?.order != undefined &&
                  orders?.data?.order.length > 0 ? (
                  orders?.data?.order.map((item, index) =>
                    item.OrdersItems.map((orderItem, index2) => (
                      <tr key={index2}>
                        <td>{orderItem?.OrderId}</td>
                        <td>{item?.Child?.name}</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: orderItem?.itemName.replace(/<[^>]+>/g, ""),
                          }}
                        >
                        </td>
                        <td>
                          <img
                            src={
                              orderItem?.image
                                ? `${image_url}${MENU_IMAGE_PATH}` +
                                orderItem?.image
                                : ""
                            }
                            alt="not found"
                            style={{ height: "50px" }}
                          />
                        </td>
                        <td>{orderItem?.price}</td>
                        <td>{orderItem?.quantity}</td>
                        <td>{item?.totalPrice}Kr</td>
                        <td>{item?.deliveryDate}</td>
                        <td>
                          <i
                            className="fa fa-file"
                            onClick={() => handleReceipt(item)}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </td>
                        <td>
                          {item?.status === 1 ? (
                            <i
                              className="fa fa-times"
                              onClick={() => {
                                handleCancel(orderItem?.OrderId, item);
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          ) : item?.status === 2 ? (
                            <>Canceled</>
                          ) : item?.status === 3 ? (
                            <>Delivered</>
                          ) : (
                            <>Unknown Status</>
                          )}
                        </td>
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan={9}>Ingen Bestillinger Fundet !!</td>
                  </tr>
                )}
              </thead>
            </table>
          </div>
          {/* Pagination component */}
          <Pagination
            totalPage={orders?.data?.pagination?.totalPages}
            currentPage={orders?.data?.pagination?.currentPage}
            fullPagination={paginationWithPage}
          />
        </div>
      </div>
      <ReceiptModal
        isRecOpen={isRecModalOpen}
        closeRecModal={closeRecModal}
        data={receiptData}
      />
      <Cancelorder
        isCancelOrderOpen={isCancelOrderModalOpen}
        iscloseCancelOrder={closeCancelOrderModal}
        id={id}
        setOrders={setOrders}
        orderData={orderData}
        orderItem={orderItem}
      />
    </div>
  );
}

export default UserOrders;
