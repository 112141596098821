import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createLogin } from '../../app/login/login.slice';
import commonFunction from '../../Utils/commonFunction';
import Loader from '../Common/Loader';

function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResendOTP = () => {
    const email = formik.values.email;
    const response = commonFunction.reSendOtp(email);
    response.then((res) => {
      if (res.status === true) {
        setTimeout(() => {
          navigate("/verifyOtp", {
            state: {
              email: email
            }
          });
        }, 1000);
      } else {
        toast.error(`Something went wrong,${res?.data?.message}`);
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setVerifyOtp(false);
  }, [])

  const validationSchema = yup.object({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required'),
  });


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    onSubmit: async (values) => {

      try {
        setLoading(true);
        const response = await dispatch(createLogin(values));
        if (response.payload.status) {
          toast.success(response.payload.message);
          setLoading(false);
          window.location.reload(true)
        } else {
          setLoading(false);
          if (response.payload.data.Otpstatus === false) {
            setVerifyOtp(true);
          } else {
            setVerifyOtp(false);
          }
          toast.error(response.payload.message);
        }
      }
      catch (error) {
        toast.error('Please wait!! Something went wrong !!');
      }

    },
    validationSchema
  });

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <main>
      <section className="login-sec wow pulse">
        <div className="container">
          <div className="login-inner">
            <div className="row">
              <div className="col-md-6">
                <form className="form_elements" onSubmit={formik.handleSubmit}>
                  <div className="form-title">
                    <h2> Login</h2>
                  </div>
                  <div className="form-group">
                    <div className="label_wrap">
                      <label htmlFor="email">Email ID</label>
                      <abbr>*</abbr>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error-message">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <div className="label_wrap">
                      <label htmlFor="password">Adgangskode</label>
                      <abbr>*</abbr>
                    </div>
                    <div className="d-flex">

                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Adgangskode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" style={{ height: '45px', cursor: 'pointer' }}>
                          <i
                            className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} toggle-password-icon`}
                            aria-hidden="true"
                            onClick={() => { setShowPassword(!showPassword) }}
                          ></i>
                        </span>
                      </div>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="error-message">{formik.errors.password}</div>
                    ) : null}
                  </div>

                  <div className="form-group d-flex justify-content-between">
                    <div className="form-check form_check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                      Husk Mig
                      </label>
                    </div>
                    <div className="forgotPass">
                      <Link to="/forgot-password">
                      Glemt Adgangskode?
                      </Link>
                    </div>
                  </div>

                  <div className="form_check">
                    <label className="form-check-label1" htmlFor="gridCheck1">
                    Har du ikke en konto?{' '}
                      <NavLink className="text-decoration-none" to="/signup" >
                      Opret bruger
                      </NavLink>
                    </label>
                  </div>
                  <br />

                  <div className="btn_submit">
                    <button type="submit" className="btn btn-1 w-100">
                      Login
                    </button>
                  </div>
                  {
                    verifyOtp && (
                      <Link onClick={handleResendOTP} style={{ cursor: 'pointer', marginTop: '10px' }}>
                        Resend OTP
                      </Link>
                    )
                  }
                </form>
              </div>
              <div className="col-md-6">
                <div className="login-img">
                  {/* Your other content goes here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
