import React, { useEffect, useState } from "react";
import Slider from "../Common/Slider/Slider";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";

const About = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [loader]);

  if (loader) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <main>
        <section className="page-info-sec">
          <div
            className="bg-fixed wow fadeIn"
            data-wow-delay="0.1s"
            style={{ background: 'url("assets/img/page-info1.png")' }}
          />
          <div
            className="bg-fixed img-overlay wow fadeIn"
            data-wow-delay="0.2s"
          />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div
                  className="page-info-block wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h1>Om OS</h1>
                  <div className="btm-line-2 text-center justify-content-center">
                    <span />
                    <span />
                  </div>
                  <p>
                  Vi er en virksomhed, der konstant arbejder på at forbedre maden og gøre det så nemt for jer som forældre at bruge siden og få en god solid frokost til jeres børn.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div className="img-style-1 wow fadeInLeft">
                  <img src="./assets/img/about-1.png" alt="Not found" />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <div
                    className="title-1 wow fadeInRight"
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <h1>Vores vision</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>

                    </p>
                    <div className="mt-3">
                      {/* <h2>Our Vision</h2> */}
                      <p>
                      Jeg startede med et pizzaria helt tilbage i 1993, men her i 2011 besluttede jeg mig for at gå til skolens madvirksomhed. Jeg har selv børn, de er store nu, men de havde ikke kantinemad på deres skole, og det syntes jeg var ærgerligt, da vi ikke altid har tid til at pakke madpakkerne til børnene. Så jeg besluttede mig for at lave skolemad, med nogle retter, der ikke er helt så kedelige, og som børnene kan lide, og samtidig have noget sundt i sig.
                      </p>
                      <ul>
                        <li>Hurtige leveringstjenester</li>
                        <li>Vi sikrer vores madkvalitet</li>
                        <li>Lav mad af professionel kok</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd bg-gray position-relative">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div>
                  <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                    <h1>Vores App er snart Klar til download</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Vi bruger app mere og mere nutildags, Appen vil gøre det
                      nemmere at bestille, bedre notifikationer. Bedre
                      muligheder får at høre jeres meninger, såsom bedre retter,
                      bedre brugervenlighed, og andre ideer.
                    </p>
                    <Link className="btn btn-1 mt-20">
                      Download Soon Avaliable
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-style-3 pl-2per img-res-md wow fadeInRight">
                  <img src="./assets/img/download-mobile.png" alt="Not found" />
                </div>
              </div>
            </div>
          </div>
          <div className="download-banner img-res-md">
            <img src="./assets/img/download-app.png" alt="Not found" />
          </div>
        </section>
        {/* as a task client says to comment this line */}
        {/* <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div>
                  <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                    <h1>Our Mission</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullam vel malesuada nisi. Donec suscipit varius risus,
                      non luctus nisl euismod sit amet. Quisque vitae metus ac
                      odio porttitor laoreet. Mauris a egestas est. Phasellus
                      nibh magna, auctor non magna vitae, aliquet malesuada
                      mauris. Sed gravida arcu et velit placerat volutpat.
                    </p>
                    <p>
                      Duis ac turpis. Integer rutrum ante eu lacus. Vestibulum
                      libero nisl, porta vel, scelerisque eget, malesuada at,
                      neque. Vivamus eget nibh. Pretium viverra suspendisse
                      potenti nullam ac tortor vitae purus. Habitant morbi
                      tristique senectus et netus et malesuada fames ac. t.
                      Nullam vel malesuada nisi. Donec suscipit varius risus,
                      non luctus nisl euismod sit amet. Quisque vitae metus ac
                      odio porttitor laoreet. Mauris a egestas est. Phasellus
                      nibh magna, auctor non magna vitae, aliquet uada mauris.
                      Sed gravida arcu et velit placerat volutpat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pl-10per wow fadeInRight">
                  <img
                    src="./assets/img/childrens-3.png"
                    className="img-fluid"
                    alt="Not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <Slider /> */}
      </main>
    </React.Fragment>
  );
};

export default About;
