import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import axios from 'axios';
import { base_url } from '../../Utils/baseUrl';
import { config } from '../../Utils/axiosconfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../app/login/login.slice';


const CloseAccount = ({ isAccountOpen, closeAccModal }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async () => {

        try {
            const response = await axios.patch(`${base_url}users/reqdeleteUserAccount`, {}, config);
            toast.success(response.data.message);
            Cookies.remove('token');
            dispatch(userLogout(config));
            closeAccModal();
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (error) {
            console.error('An error occurred while Deleting your account:', error);
            toast.error('Please wait!! Something went wrong !!');
        }
    };


    return (
        <Dialog open={isAccountOpen} onClose={closeAccModal}>
            <DialogTitle>Lukning af din konto</DialogTitle>
            <DialogContent>
            Du har anmodet om at lukke din konto. Bemærk, at kontoen ikke kan genskabes ved at gøre det. Har du penge på din konto, trækkes 50 kr. fra den, og resten tilbageføres til dit sidst brugte kort. Har du ingen penge på din madkonto eller under 50 kr., bliver du ikke opkrævet noget. Så vi anbefaler, at du bruger dine penge, inden du vælger at lukke dem. Anmodning om at lukke konto?
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAccModal} color="primary">
                Annullere
                </Button>
                <Button onClick={handleSubmit} color="primary">
                Bekræft Sletning Af Konto
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloseAccount;
