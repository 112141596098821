import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCancelTransaction } from '../../app/transaction/Transaction.slice';
import { getWalletAmount } from '../../app/wallet/wallet.slice';

const CancelPayment = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cancelMessage, setCancelMessage] = useState('');
    const transactionId = searchParams.get('transactionId');
    const [cancelPage, setCancelPage] = useState('');
    const [timer, setTimer] = useState(5); // Initialize timer with 5 seconds

    const data = {
        transactionId: transactionId
    }

    useEffect(() => {
        setCancelPage(localStorage.getItem('cancelMsg'));
        if (transactionId) {
            dispatch(getCancelTransaction(data)).then((res) => {
                setCancelMessage(res?.payload?.message);
            })
            dispatch(getWalletAmount());
        } else {
            toast.error('Something Went Wrong. Please try again.');
        }
    }, [dispatch])

    if (!transactionId) {
        navigate('/')
    }

    useEffect(() => {
        let countdown;
        if (cancelPage) {
            countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(countdown);
    }, [cancelPage]);

    useEffect(() => {
        if (cancelPage === 'transactionPage') {
            setTimeout(() => {
                navigate('/myprofile', {
                    state: {
                        showTransaction: true
                    }
                });
            }, 6000)
        } else {
            setTimeout(() => {
                navigate('/make-order');
            }, 6000)
        }
    }, [cancelPage])

    return (
        <div className="payment-container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="message-box success failed">
                        <h2> Du Har Anulleret Din Indbetaling  </h2>
                        {
                            cancelPage === 'transactionPage' && (
                                <div style={{ display: 'flex' }}>
                                    <h3>Du vil blive viderestilleret til Tank Op Siden om:</h3><h3 style={{ color: 'red' }}>{timer}</h3>
                                </div>
                            )
                        }
                        {
                            cancelPage === 'orderPage' && (
                                <div style={{ display: 'flex' }}>
                                    <h3>Du vil blive tilbage viderestillet til din tidligere bestilling om:</h3><h3 style={{ color: 'red' }}>{timer}</h3>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelPayment