import React, { useEffect } from "react";

function Terms() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <main>
        <section className="page-info-sec">
          <div
            className="bg-fixed wow fadeIn"
            data-wow-delay="0.1s"
            style={{ background: 'url("assets/img/page-info1.png")' }}
          />
          <div
            className="bg-fixed img-overlay wow fadeIn"
            data-wow-delay="0.2s"
          />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12">
                <div
                  className="page-info-block wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h1>Vilkår og betingelser</h1>
                  <div className="btm-line-2 text-center justify-content-center">
                    <span />
                    <span />
                  </div>
                  <p>Her kan se Vores Regler.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div className="img-style-1 wow fadeInLeft">
                  <img src="./assets/img/about-1.png" alt="Not found" />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <div
                    className="title-1 wow fadeInRight"
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <h1>Login - Oprettelse som Bruger</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      For at købe mad, skal du først oprette dig som bruger. Når
                      du har oprettet dig modtager du en e-mail med dit madnavn
                      og password. Dette brugernavn og password skal du så bruge
                      hver gang du skal bestille mad. Når du har logget dig ind
                      skal du indbetale et beløb på din konto som du kan købe
                      mad for. Du kan altid slette din profil og få udbetalt
                      evt. overskyden på din konto mod et gebyr på 50.-
                      <br />
                      Det er vigtigt at du altid har opdateret dine
                      kontooplysninger.
                    </p>
                    <div className="mt-3">
                      <h2>Betaling</h2>
                      <div className="btm-line-1">
                        <span />
                        <span />
                      </div>
                      <p>
                        For at købe mad, skal du indbetale penge på din konto
                        via dit dankort. Du kan kun købe mad for det beløb som
                        du har på din konto. Du kan til enhver tid indbetale
                        flere penge til din konto. Der vil være et mindre gebyr
                        pr. indbetaling til pbs som vil fremgå når du godkender
                        din indbetaling. Vi bruger quickpay.dk som
                        betalingsgateway til dankort læs mere på deres side om
                        sikkerheden ved online betaling.
                        <br />
                        <br />
                        Du må max indsætte 1000 kr
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pd bg-gray position-relative">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div>
                  <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                    <h1>Bestilling</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Vi har åben 24 timer i døgnet så du kan altid logge ind og
                      bestille mad. Når du skal bestille din mad skal du logge
                      ind og derefter vælge den dag du vil bestille mad til. Når
                      du har valgt dagen kan du frit vælge mellem alle vores
                      gode produkter. Når du er færdig med at vælge mad til den
                      dag du har valgt klikker du bare på en anden dag i
                      kalender for at bestille til en anden dag. Du kan bestille
                      op til 3 mdr. frem.
                    </p>
                    <h1>Afbestilling</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Du kan afbestille eller ændre i din bestilling, op til kl.
                      06.00 samme dag som bestillingen skal udleveres.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-style-3 pl-2per img-res-md wow fadeInRight">
                  <img src="./assets/img/download-mobile.png" alt="Not found" />
                </div>
              </div>
            </div>
          </div>
          <div className="download-banner img-res-md">
            <img src="./assets/img/download-app.png" alt="Not found" />
          </div>
        </section>
        <section className="sec-pd rel-z-1">
          <div className="top-bottom-shaps">
            <span className="shap shap-1" />
            <span className="shap shap-2" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mmb-20">
                <div>
                  <div className="title-1 wow fadeInUp" data-wow-delay="0.1s">
                    <h1>Afhentning</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Alle madpakker er mærket med et mærkat hvor det tydeligt
                      fremgår hvem madpakken tilhører. Der vil fremgå: navn og
                      klassetrin på mærkaten, sådan at der ikke kan være tvivl
                      om hvem der skal have maden. Er eleven syg eller på anden
                      måde forhindret i at afhente sin madpakke, får man ikke
                      refunderet sit køb..
                    </p>
                    <h1>Forstrydelsesret - Klager</h1>
                    <div className="btm-line-1">
                      <span />
                      <span />
                    </div>
                    <p>
                      Da du har mulighed for at ændre eller slette din
                      bestilling op til midnat har du ikke mulighed for at
                      fortryde dit køb til den dag efter kl 06.00 da maden så er
                      produceret. Hvis du har nogle klager over maden skal du
                      skrive til os via vores kontakt formular her. 
                      <br />
                      <br />
                      Priser
                      <br /> 
                      Alle priser er inkl. moms.<br /> <br />  Inaktiv Konto <br />
                      Hvis din konto ikke er brugt i 3 år har vi ret til at slette den.<br />
                      Hvis der er penge til gode vil det blive sendt tilbage til sidst brugt kort
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pl-10per wow fadeInRight">
                  <img
                    src="./assets/img/childrens-3.png"
                    className="img-fluid"
                    alt="Not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Terms;
